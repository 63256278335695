
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #101828;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
  
}

[type='checkbox']:checked {
  /* color: black; */
    /* border-radius: 25px; */
    /* width: 18px; */
    /* height: 18px; */
    /* background: url('/src/assets/icons/tick-circle.svg'); */
    /* color: transparent; */
}

[type='radio']:checked {
  color: black;
    border-radius: 25px;
    width: 24px;
    height: 24px;
    background: url('/src/assets/icons/tick-circle.svg');
    color: transparent;
}

[type='checkbox'] {
 
    /* appearance: none; Hide default checkbox appearance */
    /* width: 18px; */
    /* height: 18px; */
    /* border-radius: 38.4px; */
    border: 1.8px solid rgba(15, 19, 34, 0.12);
    /* background: #FFF; */
    /* outline: none; Remove the outline when focused */
    cursor: pointer; 
}

[type='radio'] {
 
    appearance: none; /* Hide default checkbox appearance */
    width: 18px;
    height: 18px;
    border-radius: 38.4px;
    border: 1.8px solid rgba(15, 19, 34, 0.12);
    background: #FFF;
    outline: none; /* Remove the outline when focused */
    cursor: pointer; 
}
.react-datepicker-wrapper .react-datepicker__input-container input{
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus, textarea:focus{
  outline:none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width:none !important;
}

li:focus-visible{
  outline: none;
}

li:hover{
  cursor: pointer;
}



.loadingScreen {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-panel--selected { animation-name: tabPanel; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes tabPanel { from { opacity: 0; } to { opacity: 1; } }
/* Input style */
input::placeholder{
  color: #858D98 !important;
font-family: Open Sans !important;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 20px !important;
}

textarea::placeholder{
  color: #858D98 !important;
font-family: Open Sans !important;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 20px !important;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.ps-menu-icon {
  width: unset !important;
  min-width: unset !important;
  justify-content: start !important;
  margin: 0 !important;
}
.ps-menu-button, .ps-menu-icon {
  height: unset !important;
}
.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-sidebar-container, .ps-submenu-content{
  background-color: transparent !important;
}
.ps-collapsed .ps-menu-icon{
  min-width: 100% !important;
  justify-content: center !important;
}
.ps-collapsed{
  width: 80px !important;
}
.ps-sidebar-container, .ps-menu-root,.ps-menu-root>ul{
  width:100%;
  display:flex;
  flex-direction: column;
}
.ps-sidebar-root{
  min-width: unset !important;
  border: none !important;
}
.ps-collapsed, .ps-collapsed .ps-menu-root, .ps-collapsed .ps-menu-button{
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.ps-collapsed ul{
  align-items: center;
}
.ps-menu-label{
  display: flex;
  justify-content: space-between;
}


.css-1u9des2-indicatorSeparator{
  width: 0 !important;
}

.css-13cymwt-control{
  border: none !important;
  box-shadow: none !important;  width: 100% !important;
}
.css-13cymwt-control:focus{
  border: none !important;
}
.css-10wo9uf-option{
  color: #75808F !important;
font-family: Open Sans !important;
font-size: 14px !important;
font-style: normal ;
font-weight: 600 !important;
line-height: 20px !important;
}
.css-tr4s17-option{
  border-radius: 48px !important;
  background-color: inherit;
  color: #101828 !important;
font-family: Open Sans !important;
font-size: 14px !important;
font-style: normal;
font-weight: 600 !important;
line-height: 20px !important;
}
.css-1nmdiq5-menu{
  border-radius: 16px !important;
  padding: 16px 24px;
}
.css-t3ipsp-control{
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;

}

.css-1fdsijx-ValueContainer{
  color: #75808F !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important; 
  line-height: 20px !important;
}

/* .react-calendar__tile--now {
  background: inherit !important;
}

.rdrDayToday .rdrDayNumber span:after{
  background: #1c53f4 !important;
} */



.rmdp-day.rmdp-today span{
  background-color: inherit !important;
  color: inherit !important;
}

.rmdp-header{
  margin-bottom: 24px;
  padding: 0 !important;
}

.rmdp-calendar  {
  width: 100% !important;
}

.rmdp-wrapper{
width: 100% !important;
}

.rmdp-day-picker > div{
  width: 100% !important ;
  
}
.rmdp-day-picker {
  padding: 0 !important;
}

.rmdp-week-day{
  color: #101828 !important;
font-family: General Sans Variable !important;
font-size: 14.344px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 19.125px !important; 
letter-spacing: -0.034px !important;
}

.rmdp-day{
  color:  #75808F !important;
  margin: 3px 0px;
  height: 32px !important;
    /* width: 32px !important; */
}

.rmdp-range {
  background-color: #1c53f4 !important;
  border-radius: 16px;
  color: white !important;

}
.rmdp-header-values{
  color: #101828 !important;
text-align: center;
font-family: Fredoka !important;
font-size: 16.257px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 21.038px !important;
}
/* 
th, td {

  margin:0 5px !important

} */

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border-radius: 8px;
  border: 1px solid #1c53f4 !important;
  background: #fff !important;
  box-shadow: 0px 6px 32px 0px rgba(44, 50, 169, 0.04) !important;

  color: #1C53F4 ;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


/* .custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {

  min-width: 250px;
}
 */



 /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.event-identify{
  display: flex;
  /* align-items: center; */
  column-gap: 1px;margin-bottom: 6px;

}
.event-identify-text{
  color: #0F1322;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

}