@import "../styles//breakpoints";

.jobTitle {
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}


.heading {
  font-size: 14px;
  color: #000;

}

.text {
  font-size: 12px;
  color: #000;
}

.link {
  font-size: 13px;
  color: #3b0be9;
  text-decoration: underline;
  cursor: pointer;

}

.link:hover {
  color: #000;
}