

@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/GeneralSans-Regular.otf"); font-family : "General Sans Variable"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/GeneralSans-Semibold.otf"); font-family : "General Sans Variable"; font-weight : 600;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSans.ttf"); font-family : "Open Sans"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansRomanExtraBold.ttf"); font-family : "Open Sans"; font-weight : 800;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansRomanRegular.ttf"); font-family : "Open Sans"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansBold.ttf"); font-family : "Open Sans"; font-weight : 700;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansRomanSemiBold.ttf"); font-family : "Open Sans"; font-weight : 600;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/FredokaSemiBold.ttf"); font-family : "Fredoka"; font-weight : 600;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/FredokaMedium.ttf"); font-family : "Fredoka"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/ManropeMedium.ttf"); font-family : "Manrope"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/ManropeRegular.ttf"); font-family : "Manrope"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/ManropeBold.ttf"); font-family : "Manrope"; font-weight : 700;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PlusJakartaSansExtraBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 800;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PlusJakartaSansRomanBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 700;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PlusJakartaSansRomanRegular.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 400;  }
/* @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansItalicRegular.ttf"); font-family : "Open Sans"; font-weight : 400;  } */
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRomanMedium.ttf"); font-family : "Roboto"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/InterSemiBold.ttf"); font-family : "Inter"; font-weight : 600;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRomanRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/MontserratRegular.ttf"); font-family : "Montserrat"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/MontserratMedium.ttf"); font-family : "Montserrat"; font-weight : 500;  }
/* @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/OpenSansItalicSemiBold.ttf"); font-family : "Open Sans"; font-weight : 600;  } */
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }

