:root { 
--gray_900_14:#0f132214; 
--gray_50_01:#f4f7fd; 
--gray_50_02:#f3f6fe; 
--blue_A700_28:#1c53f428; 
--gray_50_03:#f7f9ff; 
--gray_50_04:#f9f9f9; 
--gray_50_05:#f4f7ff; 
--gray_50_06:#f6f8fe; 
--light_green_A700:#26c700; 
--gray_50_07:#f5f7ff; 
--white_A700_90:#ffffff90; 
--blue_A200:#4285f4; 
--blue_gray_500_02:#707991; 
--blue_gray_500_01:#667085; 
--green_A700:#05cf68; 
--gray_900_4c:#1018284c; 
--gray_900_0a:#0f13220a; 
--blue_gray_900_01:#242d4f; 
--blue_gray_900:#292d32; 
--blue_gray_400_33:#858d9833; 
--blue_100_33:#c9d5ff33; 
--gray_100_7f:#f1f5fe7f; 
--blue_A700_19:#1c53f419; 
--amber_500:#fbbc05; 
--blue_200_1e:#9fb4ff1e; 
--blue_gray_500:#74808f; 
--blue_100_4c:#c9d5ff4c; 
--yellow_A700:#fcd503; 
--blue_gray_400_3d:#858d983d; 
--black_900_0c:#0000000c; 
--blue_50:#e7f1f8; 
--blue_200_33:#9fb4ff33; 
--white_A700_66:#ffffff66; 
--blue_A700_0f:#1c53f40f; 
--gray_900_1e:#0f13221e; 
--gray_100_01:#f4f6fc; 
--blue_100:#c9d5ff; 
--blue_gray_400_02:#858d98; 
--blue_gray_400_01:#75808f; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
--blue_100_66:#c9d5ff66; 
--indigo_700_0a:#2c32a90a; 
--indigo_600:#306c9e; 
--indigo_700_14:#2c32a914; 
--blue_gray_50:#ecf1f6; 
--red_700:#db2c2c; 
--light_blue_A400:#00beff; 
--blue_A700:#1c53f4; 
--red_900:#a31f34; 
--blue_100_3f:#c9d5ff3f; 
--red_500:#ea4335; 
--green_600:#34a853; 
--indigo_A700_19:#0d3bff19; 
--gray_50:#f7f8fb; 
--amber_A400:#ffbd00; 
--red_50:#fff2f2; 
--white_A700_33:#ffffff33; 
--black_900:#000000; 
--red_A400:#f71735; 
--blue_50_01:#e9efff; 
--blue_200_28:#9fb4ff28; 
--blue_100_99:#c9d5ff99; 
--blue_gray_50_01:#ecf0f6; 
--gray_900_02:#1b1e2a; 
--blue_200_3d:#9fb4ff3d; 
--gray_700:#6a6a6a; 
--gray_500:#a6a6a6; 
--blue_gray_400:#8a8b8c; 
--gray_900_87:#10182887; 
--indigo_50:#e4ecf9; 
--gray_900:#0f1322; 
--gray_900_01:#101828; 
--light_blue_50:#e6feff; 
--green_50:#e6ffe1; 
--orange_300:#f9c067; 
--indigo_700_02:#2c32a902; 
--gray_100:#f0f3ff; 
--white_A700_00:#ffffff00; 
--indigo_100:#c3d0e0; 
--blue_100_7a:#c9d5ff7a; 
--white_A700_01:#fdfeff; 
--gray_900_3d:#0f13223d; 
--blue_200:#9fb4ff; 
}