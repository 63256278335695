@import "./breakpoints";
* {
  box-sizing: border-box;
}
.svg-container {
  width: 1360px;
  height: 944px;
  border-radius: 32px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.svg-shape {
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1360" height="944" viewBox="0 0 1360 944" fill="none"><rect width="1360" height="944" rx="32" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1360 32C1360 14.3269 1345.67 0 1328 0H184C166.327 0 152 14.3269 152 32V120C152 137.673 137.673 152 120 152H32C14.3269 152 0 166.327 0 184V912C0 929.673 14.3269 944 32 944H1328C1345.67 944 1360 929.673 1360 912V32Z" fill="white"/></svg>');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
body {
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  // user-select: none;
}

.dhiwise-code,
.dhiwise-navigation {
  min-height: 100vh;
  overflow: hidden;
  @include max-md {
    height: 100vh;
    overflow-y: scroll;
  }
}

div{
  // user-select: none;
}
.common-pointer {
  cursor: pointer;
}
.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}
.dhiwise-navigation h1 {
  color: white;
}
.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}
.dhiwise-navigation ul {
  padding: 0;
}
.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.dhiwise-navigation a {
  color: #132cdc;
}
table {
  border-spacing: 0;
  width: 100%;
}
.common-select {
  z-index: 10000;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}
.input-wrap {
  position: relative;
}
option {
  color: #000;
}
.table-wrap {
  overflow: auto;
}
input:focus {
  outline: none;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}
.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}
.mobile-menu {
  display: none;
  cursor: pointer;
}
.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .mobile-menu {
    display: block;
  }
  .header-row {
    width: 100% !important;
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
  .common-row-list > ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}
.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}
.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1)
    scaleY(1);
}
.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.common-row-list > ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {
  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: 12px;
  border: 1.5px solid rgba(133, 141, 152, 0.2);
  // display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #0f1322;
  box-shadow: 0px 6px 32px 0px rgba(44, 50, 169, 0.04);
  border-radius: 10px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}


// "handleHide" | "handleShow"

.handleHide{
  display: none;
  @include max-md {
    display: block;
  }
}

.handleShow{
  display: block;
}

.react-datetime-picker__wrapper {
  // important all
  width: 100% !important;
  height: 100% !important;
  border-radius: 80px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  /* display: flex; */
  /* align-items: center; */
  padding-left: 12px;
  @include max-md {
    padding-left: 12px;
  }
}
